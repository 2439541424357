import React, { useState, useEffect } from "react";
import deleteIcon from "../../../src/assets/icons/deleteIcon.svg";
import { deleteShortcut, getShortcut } from "./../../slices/shortcuts";
import { IconButton, Button, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import { useDispatch } from "react-redux";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import htmlToFormattedText from "html-to-formatted-text";
import { isIframe, parseHtml } from "../../utilities/ParseHtmlToText";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const Shortcut = ({ item, id, className, user, topRef }) => {
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const { t } = useTranslation();
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ top: 0, left: 0, behavior: "smooth" });
  };
  let description = "";

  if (htmlToFormattedText(parseHtml(item?.text)).includes("+?+?+")) {
    htmlToFormattedText(parseHtml(item?.text))
      .split("+?+?+")
      .map((item) => {
        if (item.includes("https")) {
          description = description;
        } else {
          description = description + item;
        }
        return description;
      });
  } else {
    if (htmlToFormattedText(isIframe(item?.text)).includes("#?#?#")) {
      htmlToFormattedText(isIframe(item?.text))
        .split("#?#?#")
        .map((item) => {
          if (item.includes("https")) {
            description = description;
          } else {
            description = description + item;
          }
          return description;
        });
    }
  }
  return (
    <div className={"shortcuts-content"}>
      <Typography className={`shortcuts-title ${className}`}>
        {item?.title}
      </Typography>
      <Typography className={`shortcuts-description ${className}`}>
        {htmlToFormattedText(
          htmlToFormattedText(parseHtml(item?.text))
        ).includes("+?+?+") ||
        htmlToFormattedText(isIframe(item?.text)).includes("#?#?#") ||
        htmlToFormattedText(isIframe(item?.text)).includes("#?#?#")
          ? description
          : htmlToFormattedText(item?.text)}
      </Typography>
      {user.role.code !== "OPERATOR" && (
        <div className="shortcut-action-wrapper">
          <IconButton
            id="delete-shortcut"
            disableRipple
            disableFocusRipple
            onClick={() => {
              dispatch(getShortcut(item));
              scrollToTop();
            }}
          >
            <CreateIcon
              style={{
                color: "green",
                marginTop: "-8px",
              }}
            />
          </IconButton>
          <IconButton
            id="delete-shortcut"
            disableRipple
            disableFocusRipple
            onClick={() => {
              dispatch(
                deleteShortcut({
                  websiteID: user?.websiteID,
                  id: item?._id,
                })
              ).then((res) => {
                if (!res.error) {
                  showSnackbar({
                    message: t("Shortcut successfully deleted"),
                    variant: "success",
                  });
                } else {
                  showSnackbar({
                    message: t(res.error.message),
                    variant: "error",
                  });
                }
              });
            }}
          >
            <LazyLoadImage
              src={deleteIcon}
              alt="deleteIcon"
              style={{ marginTop: "-8px" }}
            />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default Shortcut;
