import React, { useEffect } from "react";
import { getAllshortcuts } from "./../../slices/shortcuts";
import { useSelector, useDispatch } from "react-redux";
import { changeNewMessage, isShortcut } from "../../slices/newMessage";
import { CircularProgress } from "@mui/material";
import Shortcut from "./Shortcut";
import htmlToFormattedText from "html-to-formatted-text";
import { isIframe, parseHtml } from "../../utilities/ParseHtmlToText";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";

const Shortcuts = ({
  className,
  id,
  tagid,
  newMessage,
  user,
  topRef,
  open,
  replyZoneId,
  firstMessage,
}) => {
  const { allShortcuts, statusAllShortcuts, status } = useSelector(
    (state) => state.shortcuts
  );

  const dispatch = useDispatch();
  useEffect(() => {
    Object.keys(allShortcuts).length === 0 &&
      ((open !== undefined && open !== false) ||
        replyZoneId === "reply-zone-shortcuts") &&
      dispatch(getAllshortcuts({ websiteID: user?.websiteID, query: `` }));
  }, []);
  const { t } = useTranslation();
  return (
    <div
      className={
        firstMessage ? "shortcuts-container-client" : "shortcuts-container"
      }
    >
      <div className="reply-card-option">
        {allShortcuts?.docs?.filter((item) => item?.tag?._id === id).length !==
          0 && <span>{t("Your Shortcuts")}</span>}
      </div>
      {tagid === "tags-shortcut" ? (
        <div style={{ width: "100%" }}>
          {status === "loading" ? (
            <CircularProgress className="circular-progress-shortcuts" />
          ) : allShortcuts?.docs?.filter((item) => item?.tag?._id === id)
              .length === 0 ? (
            <span className="tag-not-found">This Tag Has No Shortcut</span>
          ) : (
            allShortcuts?.docs
              ?.filter((item) => item?.tag?._id === id)
              .map((item) => {
                return (
                  <Shortcut
                    item={item}
                    id={id}
                    topRef={topRef}
                    key={item?._id}
                    className={className}
                    user={user}
                  />
                );
              })
          )}
        </div>
      ) : (
        (() => {
          return statusAllShortcuts === "loading" ? (
            <div className="reply-card-option">
              <span>Loading ... </span>
            </div>
          ) : (
            allShortcuts?.docs
              ?.filter(
                (item) =>
                  htmlToFormattedText(parseHtml(`${item?.title} ${item?.text}`))
                    ?.toLowerCase()
                    .includes(
                      htmlToFormattedText(parseHtml(newMessage))
                        ?.trim()
                        ?.toLowerCase()
                    ) ||
                  htmlToFormattedText(
                    parseHtml(`${item?.title} ${item?.text}`)
                  ).indexOf(
                    htmlToFormattedText(parseHtml(newMessage))
                      ?.trim()
                      .toLowerCase()
                  ) > -1
              )
              ?.map((item) => {
                let description = "";
                if (
                  htmlToFormattedText(parseHtml(item?.text)).includes("+?+?+")
                ) {
                  htmlToFormattedText(parseHtml(item?.text))
                    .split("+?+?+")
                    .map((item) => {
                      if (item.includes("https")) {
                        description = description;
                      } else {
                        description = description + item;
                      }
                      return description;
                    });
                } else {
                  if (
                    htmlToFormattedText(isIframe(item?.text)).includes("#?#?#")
                  ) {
                    htmlToFormattedText(isIframe(item?.text))
                      .split("#?#?#")
                      .map((item) => {
                        if (item.includes("https")) {
                          description = description;
                        } else {
                          description = description + item;
                        }
                        return description;
                      });
                  }
                }

                return (
                  <div
                    className="shortcuts-content"
                    key={item?._id}
                    onClick={() => {
                      dispatch(isShortcut(nanoid()));
                      dispatch(changeNewMessage(item?.text));
                    }}
                  >
                    <div className="shortcuts-title">
                      <span>{item?.title}</span>
                    </div>
                    <div className="shortcuts-description">
                      <span>
                        {" "}
                        {htmlToFormattedText(parseHtml(item?.text)).includes(
                          "+?+?+"
                        ) ||
                        htmlToFormattedText(isIframe(item?.text)).includes(
                          "#?#?#"
                        )
                          ? description
                          : htmlToFormattedText(item?.text)}
                      </span>
                    </div>
                  </div>
                );
              })
          );
        })()
      )}
    </div>
  );
};

export default Shortcuts;
